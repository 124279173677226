import React from "react";
import { useAuth } from "../auth/use-auth";
import { userContext } from "../auth/user-context";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolBar: {
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    flexGrow: 1,
  },
  ml: {
    marginLeft: "5px",
  },
}));

export default function DefaultAppBar() {
  const classes = useStyles();
  const { user } = React.useContext(userContext);
  const { logout } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" className={classes.title}>
            Welcome {user.name}
            {console.log(user.isAdmin)}
          </Typography>
          {user.isAdmin === "true" || user.isAdmin === "True" ? (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => (window.location = "/admin")}>
                  <SupervisorAccountIcon />
                  <Typography variant="subtitle2" className={classes.ml}>
                    admin
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ExitToAppIcon />
                  <Typography variant="subtitle2" className={classes.ml}>
                    logout
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <IconButton onClick={handleLogout} color="inherit">
              <ExitToAppIcon />
              <Typography variant="subtitle2" className={classes.ml}>
                logout
              </Typography>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
