import React from "react";
import axios from "axios";
import { userContext } from "../auth/user-context";
import { makeStyles } from "@material-ui/core/styles";
import GpuCard from "../components/gpu-card";
import DefaultCard from "../components/default-card";
import WaitActivation from "../components/wait-for-activation-card";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const UserView = (props) => {
  const classes = useStyles();
  const { token } = React.useContext(userContext);
  const [state, setState] = React.useState("");
  const [update, setUpdate] = React.useState(true);
  const [request, setRequest] = React.useState(false);

  const CardViewed = () => {
    if (state === "active") {
      return <GpuCard setState={setState} request={request} />;
    } else if (state === "pending") {
      return <WaitActivation setState={setState} request={request} />;
    } else {
      return <DefaultCard submit={handleSubmit} />;
    }
  };

  const handleSubmit = async (request) => {
    console.log(request);
    axios
      .post(
        `${process.env.REACT_APP_LOCAL_API_URL}/requests?token=${token}`,
        request
      )
      .then((res) => {
        console.log(res);
        setUpdate(true);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (update) {
      axios
        .get(`${process.env.REACT_APP_LOCAL_API_URL}?token=${token}`)
        .then((res) => {
          if (res.data.request) {
            console.log(res.data.request.status);

            if (res.data.request.status === "pending") {
              setRequest(res.data.request);
              setState(res.data.request.status);
            } else if (res.data.request.status === "active") {
              setRequest(res.data.request);
              setState(res.data.request.status);
            } else {
              setState("");
            }
          }
          setUpdate(false);
        });
    }
  }, [update, token]);

  return (
    <div className={classes.root}>
      <CardViewed />
    </div>
  );
};

export default UserView;
