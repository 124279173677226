import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserForm from './user-form';


export default function UserDialog(props) {
  return (
      <Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title" scroll='body' maxWidth='xs' fullWidth>
        <DialogTitle id="form-dialog-title">
            { props.edit? <>Edit User</> : <>Add User</> }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            submit your request for { props.edit? <>The User Update</> : <>The New User</> } 
          </DialogContentText>
          <UserForm user={props.user} submit={props.submit} close={props.close} edit={props.edit}/>
        </DialogContent>
      </Dialog>
  );
}