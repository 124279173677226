import React from 'react';
import { Button, Grid, FormControlLabel } from "@material-ui/core"; 
import { makeStyles } from "@material-ui/core/styles" 
import { Formik, Form, Field } from "formik";
import { Switch } from "formik-material-ui";

import * as Yup from "yup";
import CustomTextField from './text-field'

const basicInfoSchema = Yup.object().shape({
    name: Yup.string().required(),
    username: Yup.string().required(),
    password: Yup.string().required(),
    working: Yup.boolean().required(),
});


const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'hidden', 
        overflowX: 'hidden',
        marginBottom: '100px'
    },
    textField: {
        color: '#031441'
    },
    btn: {
        backgroundColor: '#0A3EC5'
    }
}))

const GpuForm = (props) => {
    const classes = useStyles()
    
    return (
        <div className={classes.root}>
            <Formik
            initialValues={{
                name: props.gpu.name,
                working: props.gpu.working,
                username: props.gpu.username,
                password: props.gpu.password
            }}
            validationSchema={basicInfoSchema}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
                props.submit(values, props.gpu.id)
            }}
            >
            { ({submitForm, isSubmitting}) => (
                <Form>
                    <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
                        <Grid item xs={12}>
                            <CustomTextField type="text" name="name" id="name-required" label="GPU Name" />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomTextField type="text" name="username" id="username-required" label="Username"/>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomTextField type="text" name="password" id="password-required" label="Password"/>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                            control={ <Field component={Switch} type="checkbox" name="working" /> }
                            label="GPU is working"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color='primary' className={classes.btn} disabled={isSubmitting} onClick={submitForm}>
                                        submit
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" disabled={isSubmitting} onClick={props.close}>
                                        cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
            </Formik>
        </div>
    );
}
 
export default GpuForm;
