import React, { useState } from "react";
import { useAuth } from '../auth/use-auth';
// import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box, Button, LinearProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import CustomeTextField from "../components/text-field";
import * as Yup from "yup"; 

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});


// const useStyles = makeStyles({
//   loginBtn: {
//     backgroundColor: "#2A3441",
//     color: "#FFFFFF",
//     marginBottom: 20,
//     "&:hover": {
//       backgroundColor: "#3A4451",
//       color: "white",
//     },
//   },
//   title: {
//     fontWeight: 'bold',
//     color: '#2A3441',
//     marginTop: 10
//   },
//   defaultBg: {
//     height: '100vh',
//     overflowX: 'hidden',
// },
// });

const Login = (props) => {
  const { login } = useAuth();
  const [error, setError] = useState(false);
  // const classes = useStyles();

  const redirect = props.location.state? props.location.state.redirect.pathname :  '/'

  const handleSubmit = (values, setSubmitting) => {  
    console.log(values)
    setTimeout(() => {
      login(values, redirect).then(res => {
        setSubmitting(false);
      }).catch(error => {
        console.log(error);
        setError(true);
    })
    }, 500);
    
  }
  
  return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Box p={3}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Box p={5}></Box>
                    <Grid item>
                      <Typography color='primary' variant="h3" align="center" gutterBottom >
                        Scheduler
                      </Typography>
                      <Box p={1}></Box>
                    </Grid>
                    <Grid item>
                      <CustomeTextField
                        name="username"
                        type="text"
                        label="Username"
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item>
                      <CustomeTextField
                        type="password"
                        label="Password"
                        name="password"
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item>
                      {isSubmitting && <LinearProgress />}
                    </Grid>
                    <Grid item>{error && <Typography>erorr</Typography>}</Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color='primary'
                        disabled={isSubmitting}
                        onClick={submitForm}
                        fullWidth
                      >
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
  );
};

export default Login;
