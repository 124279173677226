class Auth {
    constructor() {
        this.user = {
            name: null,
            isAdmin: false
        }
        this.token = null
    }

    #setCookie = (name, value, days) => {
        let date = new Date();
        date.setTime(date.getTime() + 24*60*60*1000*days);
        document.cookie = `${name}=${value} ;path=/;expires= ${date.toGMTString()}; SameSite=Lax`;
    }

    #setAuthCookie = () => this.#setCookie("isAuthenticated", true, 1);
    #delAuthCookie = () => this.#setCookie("isAuthenticated", '', -1);


    isAuthenticated() {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
          const cookiePair = cookie.split("=");
          if ("isAuthenticated" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]) === "true";
          }
        }
        // Return null if not found
        return false;
    }
    
    async login(credentials, redirect) {
        const response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/login`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials)
        }).then(res => res.json())

        if(response.message === "okay") {
            this.#setAuthCookie();
            window.location = `${process.env.REACT_APP_LOCAL_API_URL}/authenticate?token=${response.token}&name=${response.user.name}&isAdmin=${response.user.isAdmin}&redirect=${redirect}`
            return true;
        } else { return false; }
    }

    authenticate () {

    }

    async logout() {
        fetch(`${process.env.REACT_APP_LOCAL_API_URL}/logout`, {
            method: 'GET',
        }).then(res => res.json())
        .then(res => {
            if(res.message === "OK") {
                this.user.name = null;
                this.user.isAdmin = false;
                this.token = null;
                this.#delAuthCookie();
                window.location.href = "/";
            }
        }).catch( error => console.log(error))
    }
}

export default new Auth();