import { withStyles } from "@material-ui/core/styles"

export const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiTab-wrapper': {
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginLeft: '10px'
      },
    },
  })(() => null);