import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UserDialog from './user-dialog';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '80vw',
  },
  table: {
    maxWidth: '80vw'
  }
}));

export default function UsersView() {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(true);

  const handleAdd = () => {
    setUser({
      index: null,
      username: '',
      name: '',
      email: '',
      state: '',
      isAdmin: false
    });
    setOpen(true);
  }

  const handleEdit = (user, index) => {
    user['index'] = index;
    setUser(user);
    setEdit(true);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  }

  const handleDelete = (index) => {
    // let list = [...users];
    // list.splice(index, 1);
    // setUsers(list);
    axios.delete(`${process.env.REACT_APP_LOCAL_API_URL}/users/${users[index].id}`)
    .then(res => {
      console.log(res);
      setUpdate(true);
    })
  }

  const handleSubmit = (values) => {
    // let list = [...users];
    // if (user.index !== null) {
    //   const i = user.index;
    //   delete user.index;
    //   list[i] = user;
    // } else {
    //   delete user.index;
    //   list.push(user);
    // }
    // setUsers(list)
    // handleClose();
    if (values.index !== null) {
      delete user.index;
      axios.patch(`${process.env.REACT_APP_LOCAL_API_URL}/users/${user.id}`, values)
      .then(res => {
        console.log(res);
        setUpdate(true)
      })
    } else {
      delete user.index;
      axios.post(`${process.env.REACT_APP_LOCAL_API_URL}/users`, values)
      .then(res => {
        console.log(res);
        setUpdate(true)
      })
    }
    handleClose();
  }

  React.useEffect(() => {
    if(update) {
      axios.get(`${process.env.REACT_APP_LOCAL_API_URL}/users`)
      .then(res => {
        setUpdate(false);
        setUsers(res.data.users)
        console.log(res.data)
      })
    }
  }, [update])

  return (
    <div className={classes.root}>
      <Box m={2}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary'>Users</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Add" placement="top">
              <IconButton size='small' color='primary' onClick={handleAdd}>
                <PersonAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size='small'>
          <TableHead>
            <TableRow>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">State</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow>
                <TableCell align="center" component="th" scope="row">
                  {user.username}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {user.name}
                </TableCell>
                <TableCell align="center">{user.email}</TableCell>
                <TableCell align="center">{user.state}</TableCell>
                <TableCell align="center" >
                  <Tooltip title="Edit" placement="top">
                    <IconButton component="span" size='small' color='primary' onClick={() => handleEdit(user, index)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip >
                  <Tooltip title="Delete" placement="top">
                    <IconButton component="span" size='small' color='secondary' onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip >
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UserDialog user={user} open={open} close={handleClose} edit={edit} submit={handleSubmit} />
    </div>
  );
}
