import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RequestForm from './request-form';

export default function RequestDialog(props) {
  return (
      <Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title" scroll='body' maxWidth='xs' fullWidth>
        <DialogTitle id="form-dialog-title">Request GPU</DialogTitle>
        <DialogContent>
          <DialogContentText>
            submit your request for GPU 
          </DialogContentText>
          <RequestForm submit={props.submit}/>
        </DialogContent>
      </Dialog>
  );
}
