import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./use-auth";
import { userContext } from "./user-context";

export const ProtectedRoute = ({ component: Component, admin, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const { user, token } = React.useContext(userContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (admin) {
          if (isAuthenticated) {
            if (token) {
              if (user.isAdmin === "True") {
                return <Component {...props} />;
              }
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      redirect: props.location,
                    },
                  }}
                />
              );
            }
            window.location = `${process.env.REACT_APP_LOCAL_API_URL}/authenticate?redirect=${window.location.pathname}`;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    redirect: props.location,
                  },
                }}
              />
            );
          }
        } else if (isAuthenticated) {
          if (token) {
            return <Component {...props} />;
          } else {
            window.location = `${process.env.REACT_APP_LOCAL_API_URL}/authenticate?redirect=${window.location.pathname}`;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  redirect: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
