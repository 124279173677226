import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import GpuDialog from './gpu-dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import { useEffect } from 'react';
// import CustomUsedByCell from './used-by-cell';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70vw',
  },
  table: {
    maxWidth: '80vw'
  }
}));

export default function GPUsView() {
  const classes = useStyles();
  const [gpus, setGpus] = React.useState();
  const [gpu, setGpu] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(true);

  useEffect(() => {
    if(update) {
      axios.get(process.env.REACT_APP_LOCAL_API_URL + '/gpus')
      .then((response) => {
        setGpus(response.data.gpus);
        setUpdate(false)
      })
      .catch((error) => console.log(error))
    }
  }, [update]);

  const handleAdd = () => {
    setGpu({
      index: null,
      name: '',
      total_memory: 0,
      used_memory: 0,
      ssh_link: "",
      jupyter_link: "",
      working: false,
      active: false,
      last_update: "",
      username: "",
      password: "",
      free_time: "",
      occupation_time: ""
    });
    setOpen(true);
  }

  const handleEdit = (gpu, index) => {
    gpu['index'] = index;
    setGpu(gpu);
    setEdit(true);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  }

  const handleDelete = (index, gpuId) => {
    axios.delete(process.env.REACT_APP_LOCAL_API_URL + "/gpus/" + gpuId)
      .then((response) => {
        console.log(response.data);
        let list = [...gpus];
        list.splice(index, 1);
        setGpus(list);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const handleSubmit = (gpu, id) => {
    if (edit) {
      axios.patch(process.env.REACT_APP_LOCAL_API_URL + `/gpus/${id}`, { gpu })
        .then((response) => {
          console.log(response);
          setUpdate(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios.post(process.env.REACT_APP_LOCAL_API_URL + "/gpus", { gpu })
        .then((response) => {
          console.log(response);
          console.log(gpu);
          setUpdate(true);
        })
        .catch((error) => {
          console.log(gpu);
          console.log(error);
        });
    }
    handleClose();
  }


  if (!gpus) return (
    <div className={classes.root}>
      <Box m={2}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary'>GPUs</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Add" placement="top">
              <IconButton size='small' color='primary' onClick={handleAdd}>
                <PersonAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <GpuDialog gpu={gpu} open={open} close={handleClose} edit={edit} submit={handleSubmit} />
    </div>
  );

  return (
    <div className={classes.root}>
      <Box m={2}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary'>GPUs</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Add" placement="top">
              <IconButton size='small' color='primary' onClick={handleAdd}>
                <PersonAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow width="1em">
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Password</TableCell>
              <TableCell align="center">SSH Link</TableCell>
              <TableCell align="center">Jupyter Link</TableCell>
              <TableCell align="center">Total memory</TableCell>
              <TableCell align="center">Used memory</TableCell>
              <TableCell align="center">Used by</TableCell>
              <TableCell align="center">Working</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Last update</TableCell>
              <TableCell align="center">Free Time</TableCell>
              <TableCell align="center">Occupation Time</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gpus.map((gpu, index) => (
              <TableRow>
                {}
                <TableCell align="center" component="th" scope="row">
                  {gpu.name}
                </TableCell>
                <TableCell align="center">{gpu.username}</TableCell>
                <TableCell align="center">{gpu.password}</TableCell>
                <TableCell align="center">{gpu.ssh_link}</TableCell>
                <TableCell align="center">{gpu.jupyter_link}</TableCell>
                <TableCell align="center">{gpu.total_memory}</TableCell>
                <TableCell align="center">{gpu.used_memory}</TableCell>
                <TableCell align="center">{gpu.user? gpu.user.name : ""}</TableCell>
                {/* <CustomUsedByCell gpu={gpu.user? gpu.user.name : ""}></CustomUsedByCell> */}
                <TableCell align="center">{gpu.working ? 'working' : 'not working'}</TableCell>
                <TableCell align="center">{gpu.active ? 'active' : 'not active'}</TableCell>
                <TableCell align="center">{gpu.last_update}</TableCell>
                <TableCell align="center">{gpu.free_time}</TableCell>
                <TableCell align="center">{gpu.occupation_time}</TableCell>
                <TableCell align="center" >
                  <Tooltip title="Edit" placement="top">
                    <IconButton component="span" size='small' color='primary' onClick={() => handleEdit(gpu, index)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip >
                  <Tooltip title="Delete" placement="bottom">
                    <IconButton component="span" size='small' color='secondary' onClick={() => handleDelete(index, gpu.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip >
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <GpuDialog gpu={gpu} open={open} close={handleClose} edit={edit} submit={handleSubmit} />
    </div>
  );
}
