import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AdminRequestDialog from './admin-request-dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '70vw',
  },
  table: {
    maxWidth: '80vw'
  }
}));

export default function AdminRequestView() {
  const classes = useStyles();
  const [request, setRequest] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [requests, setRequests] = useState([]);
  const [update, setUpdate] = React.useState(true);

  useEffect(() => {
    if(update) {
    axios.get(process.env.REACT_APP_LOCAL_API_URL + '/requests')
      .then((resp) => {
        setRequests(resp.data.requests);
        console.log(resp.data);
        setUpdate(false);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [update]);

  const handleAdd = () => {
    setRequest({
      index: null,
      user: '',
      user_name: '',
      email: '',
      state: '',
      memory_gb: 0,
      memory_mb: 0,
      hours: 0,
      status: 'pending',
      request_time: '',
      gpu: '',
      gpu_name: '',
      expected_time:'',
      activation_time:'',
      serve_time:''

    });
    setOpen(true);
  }

  const handleEdit = (request, index) => {
    request['index'] = index;
    if (request.status === 'pending') {
      setRequest(request);
      setEdit(true);
      setOpen(true);
    } else { }
  }

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  }

  const handleDelete = (id, tableIndex) => {
    let list = [...requests];
    axios.delete(process.env.REACT_APP_LOCAL_API_URL + `/requests/${id}`)
      .then(res => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
    list.splice(tableIndex, 1);
    setRequests(list);
  }

  const handleSubmit = (request, id) => {
    
    if (edit) {
      axios.patch(process.env.REACT_APP_LOCAL_API_URL + `/requests/${id}`, request)
        .then((res) => {
          setRequest(request)
          console.log(res);
          setUpdate(true);

        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {

      axios.post(process.env.REACT_APP_LOCAL_API_URL + '/requests', request)
        .then((res) => {
          setRequest(res.data)
          console.log(res.data);
          setUpdate(true);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }

    let list = [...requests];
    if (request.index !== null) {
      const i = request.index;
      delete request.index;
      list[i] = request;
    } else {
      delete request.index;
      list.push(request);
    }
    setRequests(list)
    handleClose();
  }

  if (!requests) return (
    <div className={classes.root}>
      <Box m={2}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary'>Requests</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Add" placement="top">
              <IconButton size='small' color='primary' onClick={handleAdd}>
                <PersonAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <AdminRequestDialog request={request} open={open} close={handleClose} edit={edit} submit={handleSubmit} />
    </div>
  );

  return (
    <div className={classes.root}>
      <Box m={2}>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary'>Requests</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Add" placement="top">
              <IconButton size='small' color='primary' onClick={handleAdd}>
                <PersonAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">State </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Memory GB</TableCell>
              <TableCell align="center">Memory MB</TableCell>
              <TableCell align="center">Request time</TableCell>
              <TableCell align="center">GPU</TableCell>
              <TableCell align="center">Hours</TableCell>
              <TableCell align="center">Activation time</TableCell>
              <TableCell align="center">Serve time</TableCell>
              <TableCell align="center">Expected time</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request, index) => (
              <TableRow>
                <TableCell key={index} align="center" component="th" scope="row">
                  {request.user.name}
                </TableCell>
                <TableCell key={index} align="center">{request.user.email}</TableCell>
                <TableCell key={index} align="center">{request.user.state}</TableCell>
                <TableCell key={index} align="center">{request.status}</TableCell>
                <TableCell key={index} align="center">{request.memory_gb}</TableCell>
                <TableCell key={index} align="center">{request.memory_mb}</TableCell>
                <TableCell key={index} align="center">{request.request_time}</TableCell>
                <TableCell key={index} align="center">{request.gpu?.name}</TableCell>
                <TableCell key={index} align="center">{request.hours}</TableCell>
                <TableCell key={index} align="center">{request.activation_time}</TableCell>
                <TableCell key={index} align="center">{request.serve_time}</TableCell>
                <TableCell key={index} align="center">{request.expected_time}</TableCell>
                <TableCell key={index} align="center" >
                  <Tooltip title="Edit" placement="top">
                    <IconButton component="span" size='small' color='primary' onClick={() => handleEdit(request, index)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip >
                  <Tooltip title="Delete" placement="bottom">
                    <IconButton component="span" size='small' color='secondary' onClick={() => handleDelete(request.id, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip >
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AdminRequestDialog request={request} open={open} close={handleClose} edit={edit} submit={handleSubmit} />
    </div>
  );
}