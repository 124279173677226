import { Box } from "@material-ui/core"; 
import { Field } from "formik";
import { TextField } from "formik-material-ui";

export default function CustomTextField (props) {
    return (
        <Box m={1}>
        <Field required
        type={props.type}
        component={TextField}
        name={props.name}
        id={props.id}
        label={props.label}
        variant="outlined" fullWidth margin="dense"
        select={props.select}
        helperText={props.helperText}
        placeholder={props.placeholder}
        disabled={props.disabled}
        InputProps={props.InputProps}
        {...props}
        >
            {props.children}
        </Field>
        </Box>
    )
}
