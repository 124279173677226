import React from "react";
import { Grid } from "@material-ui/core"
import DefaultAppBar from "../components/default-app-bar"; 
import UserView from "../components/user-view";


const HomeView = () => {
  return (
    <div>
      <DefaultAppBar />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
          <UserView />
          </Grid>
        </Grid>
    </div>
  );
};

export default HomeView;
