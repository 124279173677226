import React, { useEffect, useState } from 'react';
import { Button, Grid, FormControl, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from './text-field'
import axios from 'axios';

const gb = [1, 2, 3, 4, 5, 6, 7]
const mb = [100, 200, 300, 400, 500, 600, 700, 800, 900]

const basicInfoSchema = Yup.object().shape({
    user_id: Yup.string().required(),
    memory_gb: Yup.number().min(1, 'invalied').max(7, 'invalied').required(),
    memory_mb: Yup.number().min(100, 'invalied').max(900, 'invalied').required(),
    hours: Yup.number().min(1, 'invalied').max(72, 'invalied').required(),

});


const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        marginBottom: '100px'
    },
    textField: {
        color: '#031441'
    },
    btn: {
        backgroundColor: '#0A3EC5'
    }
}))

const AdminRequestForm = (props) => {
    const classes = useStyles()
    const [gpusOptions, setGpusOptions] = useState([]);
    const [usersOptions, setUsersOptions] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_LOCAL_API_URL + '/gpus')
            .then((resp) => {
                setGpusOptions(resp.data.gpus);
                console.log(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_LOCAL_API_URL + '/users')
            .then((resp) => {
                setUsersOptions(resp.data.users);
                console.log(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    
    return (
            <div className={classes.root}>
                <Formik
                    initialValues={{
                        user_id: props.request.user ? props.request.user.id : null,
                        gpu_id: props.request.gpu ? props.request.gpu.id: null,
                        memory_gb: props.request.memory_gb,
                        memory_mb: props.request.memory_mb,
                        hours: props.request.hours
                    }}
                    validationSchema={basicInfoSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        alert(JSON.stringify(values, null, 2));
                        props.submit(values, props.request.id)
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextField type="text" name="user_id" id="user_id-required" label="User" select={true}>
                                            {usersOptions.map((user, index) => (
                                                <MenuItem key={index} value={user.id}>{user.name}</MenuItem>
                                            ))}
                                        </CustomTextField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextField type="text" name="gpu_id" id="gpu_id-required" label="Gpu" select={true}>
                                            <MenuItem value={null}> None </MenuItem>
                                            {gpusOptions.map((gpu, index) => (
                                                <MenuItem key={index} value={gpu.id}>{gpu.name}</MenuItem>
                                            ))}
                                        </CustomTextField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' color='primary'>Memory</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomTextField type="number" name="memory_gb" id="memory_gb-required" label="GB" select={true}>
                                        {gb.map((val, index) => (
                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomTextField type="number" name="memory_mb" id="memory_mb-required" label="MB" select={true}>
                                        {mb.map((val, index) => (
                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                        ))}
                                    </CustomTextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' color='primary'>Duration</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomTextField type="number" name="hours" id="Hours-required" label="Hours" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Button variant="contained" color='primary' className={classes.btn} disabled={isSubmitting} onClick={submitForm}>
                                                submit
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" disabled={isSubmitting} onClick={props.close}>
                                                cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
    );
}

export default AdminRequestForm;
