import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  progress: {
    position: "relative",
    marginTop: '150px',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  top: {
    color: "#0445EE",
    animationDuration: "2000ms",
    position: "absolute",
    left: 0
  },
  circle: {
    strokeLinecap: "round"
  },
}));

export default function Loading(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.progress}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={280}
          thickness={0.5}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          size={280}
          thickness={0.5}
          {...props}
        />
      </div>
    </div>
  );
}