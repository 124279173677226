import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Button from '@material-ui/core/Button';
import QueuePlayNextOutlinedIcon from '@material-ui/icons/QueuePlayNextOutlined';
import Typography from "@material-ui/core/Typography";
import { Grid, Paper } from "@material-ui/core";
import RequestDialog from "./request-dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 256,
    marginTop: '20px'
  },
  media: {
    height: 0,
    paddingTop: "75%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function DefaultCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
    <Paper elevation={0} className={classes.root}>
      <CardMedia
        className={classes.media}
        image="./empty.png"
        title="..."
      />
      <Grid container direction="row" justify="center" alignItems="center" spacing={6}>
        <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" component="p" align='center'>
          you have no pending requests
        </Typography>
        </Grid>
        <Grid item>
        <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<QueuePlayNextOutlinedIcon />}
        onClick={handleOpen}
      >
        add request
      </Button>
        </Grid>
      </Grid>
    </Paper>
    <RequestDialog open={open} close={handleClose} submit={props.submit}/>
    </div>
  );
}
