import React from 'react';

const StatisticsView = () => {
    return (
        <div>
            Statistics view
        </div>
    );
}
 
export default StatisticsView;