import React from "react";
import { userContext } from "../auth/user-context";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Countdown from "react-countdown";

function DateObj(props) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ padding: "10px" }}
        >
          {props.date[0]}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{ padding: "10px" }}
        >
          {props.date[1]}
        </Typography>
      </Grid>
    </Grid>
  );
}

function Field(props) {
  return (
    <TextField
      disabled
      size="small"
      label={props.label}
      defaultValue={props.value}
      variant="outlined"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 256,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.01)",
    },
    marginTop: "20px",
    marginBottom: "50px",
  },
  media: {
    height: 0,
    paddingTop: "75%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function GpuCard(props) {
  const { user } = React.useContext(userContext);
  const classes = useStyles();
  const TIME = new Date(
    Date.parse(props.request.expected_time) +
      props.request.hours * 60 * 60 * 1000
  ).toUTCString();

  const TIME2 = new Date(
    Date.parse(props.request.expected_time) +
      props.request.hours * 60 * 60 * 1000 +
      16000
  ).toUTCString();

  let str = props.request.gpu.ssh_link;
  str = `ssh ${props.request.gpu.username}@${str.slice(
    str.lastIndexOf("/") + 1,
    str.lastIndexOf(":")
  )} -p ${str.slice(str.lastIndexOf(":") + 1)}`;
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {user.name.split("")[0]}
          </Avatar>
        }
        title="GPU Credentials"
      />
      <CardMedia
        className={classes.media}
        image="./secure_server.png"
        title="..."
      />
      <CardContent>
        <Grid item xs={12}>
          <Typography variant="h6" color="Primary" gutterBottom align="center">
            Remaining Time
          </Typography>
          <Typography variant="body1" color="error" gutterBottom align="center">
            {/* convert future time into milliseconds */}
            <Countdown
              date={TIME}
              onComplete={() => {
                props.setState("");
              }}
            />
            {/* <div style={{ display: "none" }}>
              <Countdown
                date={TIME2}
                onComplete={() => {
                  props.setState("");
                }}
              />
            </div> */}
          </Typography>
        </Grid>
        <DateObj date={["start", props.request.expected_time]} />
        <DateObj date={["end", TIME]} />
        <br />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={9}>
            <Field label="Username" value={props.request.gpu.username} />
          </Grid>
          <Grid item xs={3}>
            <IconButton
              aria-label="copy Username"
              size="small"
              onClick={() =>
                navigator.clipboard.writeText(props.request.gpu.username)
              }
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Grid>

          <Grid item xs={9}>
            <Field label="Password" value={props.request.gpu.password} />
          </Grid>
          <Grid item xs={3}>
            <IconButton
              aria-label="copy password"
              size="small"
              onClick={() =>
                navigator.clipboard.writeText(props.request.gpu.password)
              }
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Grid>

          <Grid item xs={9}>
            <Field label="SSh link" value={str} />
          </Grid>
          <Grid item xs={3}>
            <IconButton
              aria-label="copy ssh link"
              size="small"
              onClick={() => navigator.clipboard.writeText(str)}
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Grid>

          <Grid item xs={9}>
            <Field
              label="Jupyter link"
              value={props.request.gpu.jupyter_link}
            />
          </Grid>
          <Grid item xs={3}>
            <IconButton
              aria-label="copy jupyter link"
              size="small"
              onClick={() =>
                navigator.clipboard.writeText(props.request.gpu.jupyter_link)
              }
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
