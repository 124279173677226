import React from "react";
import { userContext } from "../auth/user-context";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  Typography,
  Box,
} from "@material-ui/core";
import Countdown from "react-countdown";

function GridItems(props) {
  return (
    <Grid container direction="row">
      <Grid item xs={2}>
        <Box p={1}>
          <Typography variant="body2" color="textSecondary">
            {props.item[0]}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box p={1}>
          <Typography variant="body2" color="textPrimary">
            {props.item[1]}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

function CountDown(props) {
  const t = new Date(Date.parse(props.request.expected_time)).toUTCString();

  return (
    <Grid item xs={12}>
      <Typography variant="h6" color="Primary" gutterBottom align="center">
        Time until activation
      </Typography>
      <Typography variant="body1" color="error" gutterBottom align="center">
        {/* convert future time into milliseconds */}
        <Countdown
          onComplete={() => {
            props.setState("active");
          }}
          date={Date.parse(t)}
        />
        {/* 
        <div style={{ display: "none" }}>
          <Countdown
            date={new Date(
              Date.parse(props.request.expected_time) + 16000
            ).toUTCString()}
            onComplete={() => {
              props.setState(true);
            }}
          />
        </div> */}
      </Typography>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 256,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.01)",
    },
    marginTop: "20px",
    marginBottom: "50px",
  },
  media: {
    height: 0,
    paddingTop: "75%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function WaitForActivationCard(props) {
  const { user } = React.useContext(userContext);
  const classes = useStyles();
  //   const gpu = {
  //     gb: "6",
  //     mb: "600",
  //     hours: "2",
  //     activationTime: "Tue, 29 July 2021 22:52:31 GMT",
  //   };
  const TIME = new Date(
    Date.parse(props.request.expected_time) +
      props.request.hours * 60 * 60 * 1000
  ).toUTCString();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {user.name.split("")[0]}
          </Avatar>
        }
        title="Request Details"
      />
      <CardMedia
        className={classes.media}
        image="./secure_server.png"
        title="..."
      />
      <CardContent>
        <CountDown setState={props.setState} request={props.request} />
        <GridItems item={["GB:", props.request.memory_gb + " GB"]} />
        <GridItems item={["MB:", props.request.memory_mb + " MB"]} />
        <GridItems item={["Hours:", props.request.hours + " Hours"]} />
        <GridItems item={["start", props.request.expected_time]} />
        <GridItems item={["end", TIME]} />
        <br />
      </CardContent>
    </Card>
  );
}
