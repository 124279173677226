import React from "react";
import axios from "axios";
import {
  Button,
  Grid,
  MenuItem,
  Typography,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from "./text-field";

const gb = [1, 2, 3, 4, 5, 6, 7];
const mb = [100, 200, 300, 400, 500, 600, 700, 800, 900];

const basicInfoSchema = Yup.object().shape({
  memory_gb: Yup.number().min(1, "invalied").max(7, "invalied").required(),
  memory_mb: Yup.number().min(100, "invalied").max(900, "invalied").required(),
  hours: Yup.number().min(1, "invalied").max(72, "invalied").required(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "hidden",
    overflowX: "hidden",
    marginBottom: "100px",
  },
  textField: {
    color: "#031441",
  },
  btn: {
    backgroundColor: "#0A3EC5",
  },
}));

const RequestForm = (props) => {
  const classes = useStyles();
  const [gpus, setGpus] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_LOCAL_API_URL + "/gpus")
      .then((resp) => {
        setGpus(resp.data.gpus);
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          memory_gb: 0,
          memory_mb: 0,
          hours: 0,
        }}
        validationSchema={basicInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          alert(JSON.stringify(values, null, 2));
          props.submit(values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <CustomTextField
                    type="text"
                    name="gpu_id"
                    id="gpu-required"
                    label="Gpu"
                    select={true}
                  >
                    <MenuItem value={null}> None </MenuItem>
                    {gpus.map((gpu, index) => (
                      <MenuItem key={index} value={gpu.id}>
                        {gpu.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary">
                  Memory
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  type="number"
                  name="memory_gb"
                  id="gp-required"
                  label="GB"
                  select={true}
                >
                  {gb.map((val, index) => (
                    <MenuItem key={index} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  type="number"
                  name="memory_mb"
                  id="mb-required"
                  label="MB"
                  select={true}
                >
                  {mb.map((val, index) => (
                    <MenuItem key={index} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary">
                  Duration
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  type="number"
                  name="hours"
                  id="hours-required"
                  label="Hours"
                  InputProps={{ inputProps: { min: 1, max: 72 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestForm;
