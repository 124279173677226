import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { ProtectedRoute } from "./auth/protected-route";
import { Home, Admin, Login } from "./pages";
import Loading from "./auth/loading";
import { userContext } from "./auth/user-context";

export default function App() {
  const history = useHistory();
  const [user, setUser] = React.useState();
  const [token, setToken] = React.useState();
  const [isLoading, setLoading] = React.useState(true);
  const value = React.useMemo(() => ({ user, token }), [user, token]);

  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.toString() !== "") {
      setToken(params.get("token"));
      setUser({
        name: params.get("name"),
        isAdmin: params.get("admin"),
      });
      history.push(params.get("redirect"));
    }
    setLoading(false);
  }, [history]);
  if (isLoading) return <Loading />;
  else
    return (
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <userContext.Provider value={value}>
            <ProtectedRoute path="/" exact component={Home} />
            <ProtectedRoute path="/admin" component={Admin} admin={true} />
          </userContext.Provider>
        </Switch>
      </Router>
    );
}
