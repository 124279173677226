import React from "react";
import { Button, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from "./text-field";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";

const basicInfoSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  state: Yup.string().required("Required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "hidden",
    overflowX: "hidden",
    marginBottom: "100px",
  },
  textField: {
    color: "#031441",
  },
  btn: {
    backgroundColor: "#0A3EC5",
  },
}));

const UserForm = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          username: props.user.username,
          name: props.user.name,
          email: props.user.email,
          state: props.user.state,
          admin: props.user.isAdmin,
        }}
        validationSchema={basicInfoSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          values["index"] = props.user.index;
          alert(JSON.stringify(values, null, 2));
          props.submit(values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <CustomTextField
                  type="text"
                  name="username"
                  id="username-required"
                  label="Username"
                  disabled={props.edit}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  type="text"
                  name="name"
                  id="name-required"
                  label="Name"
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  type="email"
                  name="email"
                  id="email-required"
                  label="Email"
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  type="text"
                  name="state"
                  id="state-required"
                  label="State"
                  select={true}
                >
                  <MenuItem value="normal">Normal</MenuItem>
                  <MenuItem value="urgent">Urgent</MenuItem>
                </CustomTextField>
              </Grid>

              <Grid item>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="admin"
                  Label={{ label: "is admin" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      submit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={props.close}
                    >
                      cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
